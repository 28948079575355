import "~/vendor/lib/fiftyfive-utils/feature-detect";
import "~/vendor/lib/fiftyfive-utils/cookies";
import "~/vendor/lib/fiftyfive-utils/dump";
import "~/vendor/lib/fiftyfive-utils/strftime";
import "~/vendor/lib/jquery";
import "~/vendor/lib/jquery-ui.core";
import "~/vendor/lib/jquery-ui.widget";
import "~/vendor/lib/jquery-ui.position";
import "~/vendor/lib/jquery.ba-resize";
import "~/vendor/lib/jquery.special-events.scroll";
import "~/vendor/lib/jquery.waypoints";
import "~/vendor/lib/fiftyfive-utils/55_utils";
import "~/vendor/lib/fiftyfive-utils/jquery.55_utils";
import "~/vendor/lib/fiftyfive-utils/jquery.ui.unsupportedbrowserwarning";
import "~/jquery.ui.flyout";
import "~/common";
import "~/mask";
import "~/controllers";

import Rails from "rails-ujs";
if (!window._rails_loaded) {
  Rails.start();
}
