import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["view", "edit", "input", "count"];

  edit() {
    this.viewTarget.style.display = "none";
    this.editTarget.style.removeProperty("display");
    this.inputTarget.focus();
    this.count();
  }

  cancel() {
    this.viewTarget.style.removeProperty("display");
    this.editTarget.style.display = "none";
  }

  count() {
    this.countTarget.innerHTML = this.inputTarget.value.length;
  }
}
