/*------------------------------------------------------------------------------
| common.js
| Device Scout v1.1
| Author(s): Richa Avasthi
| Created: 2011-05-19
|
| Perform this JS on every page load.
------------------------------------------------------------------------------*/

// Array utilities
Array.max = function( array ){
    return Math.max.apply( Math, array );
};
Array.min = function( array ){
    return Math.min.apply( Math, array );
};

// Browser sniffing utilities.
function isiPhone()
{
    if(navigator.userAgent.match(/iPhone/i))
    {
        return true;
    }
}

function isiPad()
{
    if(navigator.userAgent.match(/iPad/i))
    {
        return true;
    }
}

function isFirefox()
{
    if(navigator.userAgent.match(/Firefox\/(\d+\.\d+)/))
    {
        return true;
    }
}

function isSupportedBrowser()
{
    if(navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Chrome/i) ||
       navigator.userAgent.match(/Safari/i) || navigator.userAgent.match(/Firefox/i))
    {
        return true;
    }
}

function deviceOrientation()
{
    if(orientation == 0 || orientation == 180)
    {
        return "portrait";
    }
    else if(orientation == 90 || orientation == -90)
    {
        return "landscape";
    }
}

jQuery.fn.inViewport = function() {
    var ovp = this.eq(0).outsideViewport();
    window.console.log(this.get(0).id + (!ovp.top) && (!ovp.right) && (!ovp.bottom) && (!ovp.left) ? " " : " not " + "in viewport");
    return (!ovp.top) && (!ovp.right) && (!ovp.bottom) && (!ovp.left);
};


(function($) {
    // Resize content to fill the viewport if the page is shorter than the viewport.
    function resizeShortPage()
    {
        var win = $(window);
        var body = $("body");
        var content = $("#content");
        var contentHeader = $(".content-header");
        var contentContainer = $(".content-container");
        if(body.height() < win.height())
        {
            body.height(win.height() - body.css("paddingTop").replace(/px|rem/, '') -
                                       body.css("paddingBottom").replace(/px|rem/, ''));
            content.height(body.height() - $("header").outerHeight() - 1);
            if(contentHeader.is(":visible"))
            {
                contentContainer.height(content.height() - contentHeader.outerHeight() - 33);
            }
            else
            {
                contentContainer.height(content.height() - 33);
            }
        }
    }

    function workaroundFlashSafariBug()
    {
        var $innerFlash = $(".flash").children(".inner");
        if($innerFlash.length > 0 && $innerFlash.is(":not(:empty)") &&
           navigator.userAgent.match(/Safari/i))
        {
          $innerFlash.css({ visibility: "hidden" }).css({ visibility: "visible" });
        }
    }

    $(function() {
        // Browser sniffing
        $("html").addClass(fiftyfive.util.getIEClass());
        if(isFirefox())
        {
            $("html").addClass("ff");
        }
        if(!isSupportedBrowser())
        {
            $("html").addClass("unsupported-browser");
        }
        if(isiPhone())
        {
            $("html").addClass("iphone");
        }
        if(isiPad())
        {
            $("html").addClass("ipad");
        }

        // Show a warning to users on an unsupported browser.
        $("#unsupported-browser-warning").unsupportedbrowserwarningwidget();

        // http://adactio.com/journal/4470/
        if (isiPhone() || isiPad())
        {
            var viewportmeta = $('meta[name="viewport"]');
            if (viewportmeta.length > 0)
            {
                viewportmeta.attr("content",
                    "width=device-width, minimum-scale=1.0, maximum-scale=1.0");
                $("body").bind("gesturestart", function() {
                    viewportmeta.attr("content",
                        "width=device-width, minimum-scale=0.25, maximum-scale=1.6");
                });
            }
        }

        /*
        ** http://bugs.jquery.com/ticket/6446
        ** MobileSafari reports the wrong values when using $.fn.offset(). This replaces it with
        ** a vanilla JS offset calculation.
        */
        if( parseFloat(((/CPU.+OS ([0-9_]{3}).*AppleWebkit.*Mobile/i.exec(navigator.userAgent)) || [0,'4_2'])[1].replace('_','.')) < 4.1) {
           $.fn.Oldoffset = $.fn.offset;
           $.fn.offset = function() {
              var result = $(this).Oldoffset();
              result.top -= window.scrollY;
              result.left -= window.scrollX;

              return result;
           }
        }

        // User flyout menu
        var userMenuActivator = $(".user-menu-activate");
        userMenuActivator.flyoutwidget({
            flyoutContainerSelector: ".user-menu-container",
            flyoutSelector: ".user-menu",
            positionOptions: {
                my: "right top",
                at: "right bottom",
                offset: "0 15px"
            }
        });

        // Workaround an apparent Safari bug for pages with the flash.
        workaroundFlashSafariBug();

        resizeShortPage();
        $(window).resize(resizeShortPage);
    });
})(jQuery);

// TODO: use ES modules
window.isFirefox = isFirefox;
