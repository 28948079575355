/*------------------------------------------------------------------------------
| jquery.ui.flyout.js
| Device Scout v1.1
| Author(s): Richa Avasthi
| Created: 2011-07-11
|
| file description.
------------------------------------------------------------------------------*/

(function($) {
  /*--------------------------------------------------------------------------
    | flyoutwidget
    |
    | A jQuery UI-based widget for operating a flyout with arbitrary content.
    | Markup is assumed to be as follows:
    |
    |   <div class="flyout-container">
    |     <a href="javascript:;" class="flyout-activator">link text</a>
    |     <div class="flyout">
    |       …
    |     </div>
    |   </div> <!-- /.flyout-container -->
    |
    | Where you can change the container and flyout selectors (and pass them in
    | as options), and the element the widget is on is a.flyout-activator.
    |
    | Requirements: jquery-1.6.2, jquery-ui-1.8.14.widget,
    |               jquery-ui-1.8.14.position
    --------------------------------------------------------------------------*/
  $.widget("ui.flyoutwidget", {
    // Set default options
    options: {
      activeClass: "active",
      containerSelector: ".flyout-container",
      flyoutSelector: ".flyout",
      positionOptions: {
        my: "left top",
        at: "left bottom",
        offset: "0 0"
      }
    },

    _create: function() {
      if (
        this.options.positionOptions.of === undefined ||
        this.options.positionOptions.of === null
      ) {
        this.options.positionOptions.of = this.element;
      }

      this.activeClass = this.options.activeClass;
      this.flyoutPositioned = false;
      this.flyoutContainerSelector = this.options.containerSelector;
      this.flyoutSelector = this.options.flyoutSelector;
      this.flyoutContainer = this.element.closest(this.flyoutContainerSelector);
      this.flyout = this.element.siblings(this.flyoutSelector);

      // Hide the link highlight style on iOS.
      this.element.css("-webkit-tap-highlight-color", "rgba(0,0,0,0)");

      this.eventName = "click";

      this.element.bind(
        this.eventName + ".flyout",
        $.proxy(this.flyoutToggle, this)
      );
      // Make sure we can dismiss the flyout by clicking elsewhere on the page.
      this.registerBodyClickHandler();
    },

    flyoutToggle: function(event) {
      if (this.flyout.is(":visible")) {
        this.flyout.removeClass(this.activeClass);
      } else {
        this.flyoutHideAll();
        this.flyout.addClass(this.activeClass);
        this._trigger("beforeShow", event, {
          activator: this.element,
          flyout: this.flyout
        });
        if (!this.flyoutPositioned) {
          this.flyout.position(this.options.positionOptions);
          this.flyoutPositioned = true;
        }
      }
    },

    flyoutHideAll: function() {
      $(this.flyoutSelector).removeClass(this.activeClass);
    },

    registerBodyClickHandler: function() {
      var self = this;
      $("body").bind(this.eventName + ".flyout", function(event) {
        var cont = $(event.target).closest(self.flyoutContainerSelector);
        if (cont.length == 0) {
          self.flyoutHideAll();
        }
      });
    }
  });
})(jQuery);
