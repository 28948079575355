import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["activator", "container", "flyout"];

  connect() {
    jQuery(this.activatorTarget).flyoutwidget({
      activeClass: "popover--active",
      containerSelector: this.element,
      flyoutSelector: this.flyoutTarget,
      positionOptions: {
        my: "center top",
        at: "center bottom",
        offset: "0 12px",
        collision: "none none",
      },
    });
  }
}
