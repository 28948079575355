(function ($) {
  function initMasks() {
    $("[data-mask]").each(function () {
      var text = $(this).data("mask-original") || $.trim($(this).text());
      $(this).data("mask-original", text);
      applyMask($(this));
    });

    $(document).on("click.mask", handleClick);
  }

  function applyMask($el) {
    $el.text("••••••••");
    $el.data("mask-applied", true);
    $el.addClass("mask--covered");
  }

  function removeMask($el) {
    if ($el.data("mask-applied")) {
      $el.text($el.data("mask-original"));
      $el.removeData("mask-applied");
      $el.removeClass("mask--covered");
    }
  }

  function handleClick(evt) {
    var $el = $(evt.target);

    $("[data-mask]").each(function () {
      if ($el.is($(this))) {
        removeMask($el);
      } else {
        applyMask($(this));
      }
    });
  }

  $(document).ready(initMasks);
  $(window).on("visionbot:deviceinfo", initMasks);
})(jQuery);
